/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
// import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
// import MDBadge from "../../components/MDBadge";
import { Button, Icon } from "@mui/material";
import { usersAPI } from "../../assets/apiFunctions";
import MDButton from "../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "../../examples/Tables/DataTable";
import AlertDialog from "../../components/dialog/dialog";
import { useUserContext } from "../../context/usersContext";
import userHooks from "./userHooks";
import { TBText } from "../../components/tableComponents/tableComponents";
import { utils } from "../../assets/assetsFunctions";

const UsersTable = () => {
  const { users, navigate } = useUserContext();
  const { onUserChange } = userHooks();
  const [loadingButton, setLoadingButton] = useState(null);
  function handleChangeUserStatus(status, id) {
    setLoadingButton(id);
    usersAPI
      .disableUser({ status: status }, id)
      .then((res) => {
        const data = { status: !status };
        onUserChange("update", data, id);
      })
      .finally(() => setLoadingButton(null));
  }

  function handleDelete(id) {
    usersAPI
      .deleteUser(id)
      .then((res) => {
        onUserChange("delete", {}, id);
      })
      .catch((e) => console.log(e));
  }
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left" display={"flex"} columnGap={5}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography
        component="a"
        href={`tel:${title}`}
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        <Icon color={"success"} fontSize={"small"}>
          phone
        </Icon>
      </MDTypography>
    </MDBox>
  );

  const Status = ({ status, id }) => (
    <MDBox ml={-1}>
      <MDButton
        color={status === 0 ? "success" : "light"}
        size={"small"}
        onClick={() => handleChangeUserStatus(status, id)}
        disabled={loadingButton && loadingButton === id ? true : false}
      >
        {loadingButton && loadingButton === id ? (
          <CircularProgress color={"secondary"} size={"20px"} />
        ) : status === 0 ? (
          "Enabled"
        ) : (
          "Disabled"
        )}
      </MDButton>
    </MDBox>
  );

  const Employed = ({ employed }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {employed || "01-01-2022"}
    </MDTypography>
  );
  const Department = ({ department }) => (
    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      {department}
    </MDTypography>
  );

  const Action = ({ action, id, name }) => (
    <MDBox display={"flex"} gap={1} alignItems={"center"}>
      <AlertDialog
        onAgree={(e) => handleDelete(id)}
        id={id}
        triggerText={"Delete"}
        title={"name"}
        disagreeText={"Cancel"}
        content={`Are you sure you want to delete " ${name}"?`}
        agreeText={"delete"}
        icon={<Icon color={"error"}>delete</Icon>}
      />
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        onClick={() => navigate(`/users/edit/?id=${id}`)}
      >
        {action}
      </MDTypography>
    </MDBox>
  );

  const rows =
    users &&
    users.length > 0 &&
    users?.map((user) => ({
      author: <Author image={user.image} name={user.name} email={user.email} />,
      department: <Department department={user?.department?.name} />,
      phone: <Job title={user?.phone} />,
      type: <TBText text={utils.returnObjectFromArray(user?.roles)?.name} />,
      status: <Status status={user.disabled} id={user.id} />,
      // employed: <Employed employed={user.employed} />,
      action: <Action action="Edit" id={user.id} name={user.name} />,
    }));

  const columns = [
    { Header: "Name", accessor: "author", width: "20%", align: "left" },
    { Header: "Type", accessor: "type", align: "left" },
    // { Header: "Phone", accessor: "phone", align: "left" },
    { Header: "Department", accessor: "department", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];
  if (!rows || !columns) return null;
  return (
    <DataTable
      table={{ rows, columns }}
      isSorted={false}
      canSearch={false}
      entriesPerPage={true}
      showTotalEntries={true}
      noEndBorder
    />
  );
};

export default React.memo(UsersTable);
