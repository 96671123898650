import React from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Add from "./add";
import View from "./view";
import { BranchesContextProvider } from "../../context/brancheContext";

//Branches Layout
const Index = (props) => {
  return (
    <MDBox pt={6} pb={3}>
      <BranchesContextProvider>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Set Brand"} />
          <MDBox pt={3}></MDBox>
          <Add />
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <View />
        </Card>
      </BranchesContextProvider>
    </MDBox>
  );
};

export default Index;
