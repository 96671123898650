import React, { useEffect } from "react";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Card from "@mui/material/Card";
import MDBox from "@mui/material/Box";
import JobApplicantsTable from "./job-applicants-table";
import { jobsAPI } from "../../assets/prefixApis/jobsAPI";

/**
 * Job applicants index page
 * */
// https://srobh.rawad.net/resumes/1715041705.pdf
const View = () => {
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Job applicants"} />
        <JobApplicantsTable />
      </Card>
    </MDBox>
  );
};

export default View;
