import React, { useState } from "react";
import { Card, Grid, Box, FormHelperText, Divider } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import SubmitForm from "../../components/forms/submitForm";
import formFields, { initialUser } from "./usersInputFildes";
import { DatePicker } from "../../components/datePicker";
import MDRadio from "../../components/MDRadio/MDRadio";
import UseFormData from "../../hooks/useFormData";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import userHooks from "./userHooks";
import UsersTable from "./usersTable";
import CurrencyText from "../../components/MDTypography/currency";

const AddUser = (props) => {
  const { user, submit, handleChange, onReset, handleChangeDate, options } = userHooks();
  const typesDescription = [
    { title: "admin:", desc: "manage the system (add , edit,delete)" },
    { title: "observer:", desc: "only monitoring (Shehk hadi)" },
    { title: "user:", desc: "add a brand transaction only" },
  ];
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Add User"} />
        <MDBox pt={3}></MDBox>
        <SubmitForm
          user={user}
          submit={submit}
          handleChange={handleChange}
          inputs={formFields}
          reset={onReset}
          isAfter={true}
        >
          <Divider sx={{ mx: 1, width: "100%" }} color={"red"} light={false} textAlign={"center"} />
          {/*<Grid item xs={12} sm={4}>*/}
          {/*  <Box sx={{ m: 2 }} justifySelf={"self-start"} xs={12} sm={12} lg={12}>*/}
          {/*    <FormHelperText id="outlined-weight-helper-text">*/}
          {/*      <MDTypography*/}
          {/*        textTransform={"capitalize"}*/}
          {/*        variant="button"*/}
          {/*        fontWeight="bold"*/}
          {/*        color="text"*/}
          {/*      >*/}
          {/*        Start Date*/}
          {/*      </MDTypography>*/}
          {/*    </FormHelperText>*/}
          {/*    <DatePicker*/}
          {/*      name={"startDate"}*/}
          {/*      value={user?.startDate | null}*/}
          {/*      onChange={handleChangeDate}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          <Grid column alignItems={"center"} gap={1} sm={12}>
            {typesDescription.map((v, index) => (
              <MDBox display={"flex"} alignItems={"center"} gap={1} key={index}>
                <Box
                  sx={{
                    width: 10, // Diameter of the circle
                    height: 10, // Diameter of the circle
                    backgroundColor: "blue", // Color of the circle
                    borderRadius: "50%", // This makes it a circle
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white", // Text color inside the circle
                    fontSize: "16px", // Font size for the text
                  }}
                />
                <MDTypography
                  textTransform={"capitalize"}
                  variant="button"
                  fontWeight="bold"
                  color="text"
                >
                  {v.title}
                </MDTypography>{" "}
                <MDTypography
                  textTransform={"capitalize"}
                  variant="button"
                  fontWeight="light"
                  color="dark"
                >
                  {v.desc}
                </MDTypography>
              </MDBox>
            ))}
          </Grid>
          <Grid item xs={12} sm={4} p={2}>
            <FormHelperText id="outlined-weight-helper-text">
              <MDTypography
                textTransform={"capitalize"}
                variant="button"
                fontWeight="bold"
                color="text"
              >
                User Type
              </MDTypography>
            </FormHelperText>
            <MDRadio
              properties={{ name: "role", options: options }}
              options={options}
              value={user}
              name={"role"}
              onChange={handleChange}
              sx={{ m: 2, width: "25ch" }}
            />
          </Grid>
        </SubmitForm>
      </Card>
      <UsersTable />
    </MDBox>
  );
};

export default React.memo(AddUser);
