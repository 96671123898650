import React, { useState } from "react";
import moment from "moment/moment";
import { setLoader, useMaterialUIController } from "../../../context";
import { getGlobalContext } from "../../../context/globalContext";
import { workingHoursAPI } from "../../../assets/prefixApis/workingHoursAPI";

const WorkHoursReportHooks = () => {
  const today = moment();
  const [date, setDate] = React.useState({ startDate: today, endDate: today });
  const [controller, dispatch] = useMaterialUIController();
  const [data, setData] = useState();
  const { users } = getGlobalContext();
  const [selectedUser, setSelectedUser] = useState([]);

  function handleChangeDate(type, e) {
    setDate({ ...date, [type]: e });
  }

  function submit() {
    const usersIds = selectedUser.map((e) => e.id);
    setLoader(dispatch, true);
    workingHoursAPI
      .getHoursBetweenDates({
        start_date: date.startDate,
        end_date: date.endDate,
        user_id: usersIds,
      })
      .then((res) => {
        const working_days = res.data.working_days;
        setData(working_days);
      })
      .catch((e) => setData(null))
      .finally(() => setLoader(dispatch, false));
  }
  function selectUser(event) {
    const {
      target: { value },
    } = event;
    setSelectedUser(typeof value === "string" ? value.split(",") : value);
  }
  return {
    selectUser,
    submit,
    handleChangeDate,
    date,
    setDate,
    data,
    setData,
    users,
    selectedUser,
    setSelectedUser,
  };
};

export default WorkHoursReportHooks;
