import React from "react";
import RenderHourComp from "./RenderTransactionComp";
import MDBox from "../../../../components/MDBox";
import PropTypes from "prop-types";

const RenderTransaction = ({ transactions, isAdmin }) => {
  return (
    <MDBox display="flex" flexDirection="column" width={"100%"} gap={1}>
      {transactions &&
        transactions
          .sort((s, f) => s.brand_name.localeCompare(f.brand_name))

          .map((tr) => (
            <RenderHourComp transaction={tr} key={tr.id || tr.working_hour_id} isAdmin={isAdmin} />
          ))}
    </MDBox>
  );
};
export default RenderTransaction;

RenderTransaction.propTypes = {
  transactions: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
