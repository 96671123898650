import React from "react";
import List from "./list";
import deleteUpdateHooks from "./deleteUpdateHooks";
import SubmitForm from "../../../components/forms/submitForm";
import inputs from "./inputs";
import MDBox from "../../../components/MDBox";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { DatePicker } from "../../../components/datePicker";
import MDButton from "../../../components/MDButton";
import Divider from "@mui/material/Divider";
import LayoutHeader from "../../../components/layoutHeader/layoutHeader";
import Card from "@mui/material/Card";

const Index = () => {
  const { data, date, handleChangeDate, submit } = deleteUpdateHooks();
  return (
    <div>
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Delete Transaction Page"} />
          <MDBox p={3}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
              mt={2}
              gap={1}
            >
              <Grid item>
                <DatePicker
                  value={date.startDate}
                  // defaultValue={date.startDate}
                  onChange={(e) =>
                    // setDate(e);
                    handleChangeDate("startDate", e)
                  }
                />
              </Grid>
              <Grid item>
                <DatePicker
                  value={date.endDate}
                  onChange={(e) =>
                    // setDate(e);
                    handleChangeDate("endDate", e)
                  }
                />
              </Grid>
              <Grid item>
                <MDButton fullWidth variant={"outlined"} color={"info"} onClick={() => submit()}>
                  Search
                </MDButton>
              </Grid>
            </Stack>
            {/*this to choose multi users*/}
            {/*{users && (*/}
            {/*  <MultipleSelectPlaceholder*/}
            {/*    options={users}*/}
            {/*    handleChange={selectUser}*/}
            {/*    users={selectedUser}*/}
            {/*  />*/}
            {/*)}*/}
            <Divider />
          </MDBox>
        </Card>
      </MDBox>
      <List transactions={data} />
    </div>
  );
};

export default Index;
