import React, { useEffect, useState } from "react";
import SubmitForm from "../../components/forms/submitForm";
import inputs from "./roomTypesInputs";
import { getAllMaterialVariables, setLoader, useMaterialUIController } from "../../context";
import UseFormData from "../../hooks/useFormData";
import { roomsAPI } from "../../assets/apiFunctions";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import PropTypes from "prop-types";
import { SERVER } from "../../assets/axiosInstance";
import { pushMessages } from "../../hooks/pushMessages";
import { SetNotificationClass } from "../../assets/messageNotification";

//add room types

const Add = ({ dataToUpdate }) => {
  const [type, setType] = useState(null);
  const { showHideLoader, showMessage } = pushMessages();
  const [roomImg, setRoomImg] = useState();
  const [displayImage, setImageDisplay] = useState();
  //
  function submit(e) {
    let alert = new SetNotificationClass();
    e.preventDefault();
    showHideLoader(true);
    const formData = UseFormData([{ name: "room", data: type }]);
    formData.append("room_img", roomImg);
    roomsAPI
      .createOrUpdate(formData)
      .then((res) => {
        setType({});
        setImageDisplay();
        showMessage(alert.SUCCESS_OPERATION());
      })
      .catch((e) => showMessage(alert.FAILED_OPERATION()))
      .finally(() => showHideLoader(false));
  }
  //
  function handleChange(e) {
    if (e !== null) {
      if (e.target.name === "room_img") {
        setRoomImg(e.target.files[0]);
      } else {
        setType({ ...type, [e.target.name]: e.target.value });
      }
    }
  }

  useEffect(() => {
    if (roomImg) {
      const file = roomImg;
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageDisplay(reader.result); // Set the result to state to trigger a re-render
      };
      reader.readAsDataURL(file);
    } else {
      setImageDisplay(null);
    }
  }, [roomImg]);
  useEffect(() => {
    if (dataToUpdate) {
      let d = dataToUpdate[0];
      setType(d);
      setImageDisplay(`${SERVER}/images/${d.room_img}`);
    }
  }, [dataToUpdate]);
  return (
    <div>
      <SubmitForm
        user={type}
        submit={submit}
        handleChange={handleChange}
        inputs={inputs}
        isAfter={true}
      >
        <MDBox style={{ width: 300, height: 200, overflow: "hidden" }} position={"relative"}>
          {displayImage ? (
            <>
              <MDButton
                color={"error"}
                sx={{ position: "absolute" }}
                size={"small"}
                variant={"text"}
                onClick={() => setRoomImg(null)}
              >
                delete image
              </MDButton>
              <img style={{ objectFit: "cover", width: "100%" }} src={displayImage || ""} />
            </>
          ) : (
            <MDBox>
              <MDTypography fontWeight={"light"}>Image will display here</MDTypography>
            </MDBox>
          )}
        </MDBox>
      </SubmitForm>
    </div>
  );
};
export default Add;

Add.propTypes = {
  dataToUpdate: PropTypes.array,
};
