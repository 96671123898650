/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Plann for 11-02-2023
        </MDTypography>{" "}
        <MDTypography variant="button" color="text" fontWeight="regular">
          11:00 Uhr
        </MDTypography>
        <MDBox
          mt={0}
          mb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <MDBox>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {/*<MDTypography display="inline" variant="body2" verticalAlign="middle">*/}
              {/*  <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>*/}
              {/*</MDTypography>*/}
              {/*&nbsp;*/}
              <MDTypography variant="button" color="text" fontWeight="medium">
                Rawad &
              </MDTypography>{" "}
              Roman
            </MDTypography>
          </MDBox>
          <MDBox mt={0} mb={2} display={"flex"} gap={1}>
            <MDTypography variant="button" color="text" fontWeight="medium">
              <Icon fontSize="small">directions_car</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="medium">
              MO134
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="success"
          icon="accessible"
          title="Frau Shmidt"
          dateTime="10:50 PM"
          // description={"home to krankenhouse"}
          from={"home"}
          to={"VKH"}
        />{" "}
        <TimelineItem
          color="success"
          icon="accessible"
          title="Frau Behrnard"
          dateTime="12:00 PM"
          // description={"home to krankenhouse"}
          from={"home"}
          to={"VKH"}
        />{" "}
        <TimelineItem
          color="error"
          icon="bed-patient"
          title="Frau Wassem"
          dateTime="13:00 PM"
          // description={"home to krankenhouse"}
          to={"home"}
          from={"VKH"}
        />
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
// <TimelineItem
//     color="error"
//     icon="bed-patient"
//     title="Herr Kam"
//     dateTime="9:20 PM"
//     description={"home to krankenhouse"}
// />
// <TimelineItem
//     color="info"
//     icon="shopping_cart"
//     title="Server payments for April"
//     dateTime="21 DEC 9:34 PM"
// />
// <TimelineItem
//     color="warning"
//     icon="payment"
//     title="New card added for order #4395133"
//     dateTime="20 DEC 2:20 AM"
// />
// <TimelineItem
//     color="primary"
//     icon="vpn_key"
//     title="New card added for order #4395133"
//     dateTime="18 DEC 4:54 AM"
//     lastItem
// />
