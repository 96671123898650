import React from "react";
import Typography from "@mui/material/Typography";
import MDTypography from "../index";
//eslint-disable-next-line
const CurrencyText = ({ value, currencySymbol = "", locale = "en-US", ...props }) => {
  // Function to format the value as currency or decimal
  const formatCurrency = (value) => {
    if (isNaN(value)) return "";

    if (currencySymbol) {
      // Format with currency
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencySymbol,
        minimumFractionDigits: 0,
      }).format(value);
    } else {
      // Format as a decimal number without currency
      return new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(value);
    }
  };
  return <MDTypography {...props}>{formatCurrency(value)}</MDTypography>;
};

export default CurrencyText;
