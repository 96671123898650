import { useLocation, Navigate, Outlet } from "react-router-dom";
import { setLogout, setRoles, useMaterialUIController } from "../context";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import WithSideBarLayout from "./withSideBarLayout/withSideBarLayout";
import { usersAPI } from "../assets/apiFunctions";
import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import MDBox from "./MDBox";

const RequireAuth = ({ allowedRoles }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { userName, roles } = controller;
  const location = useLocation();
  // useEffect(() => {
  //   if (!roles || roles?.r.length == 0) {
  //     if (userName) {
  //       usersAPI
  //         .getUserRoles()
  //         .then((res) => {
  //           setRoles(dispatch, res.data.roles);
  //         })
  //         .catch((e) => {
  //           setRoles(dispatch, "error");
  //           console.log("f", e);
  //         });
  //     } else {
  //       setRoles(dispatch, "error");
  //       return;
  //     }
  //   }
  // }, []);
  useEffect(() => {}, [roles]);
  if (!roles || !userName)
    return <Navigate to={"/authentication/sign-in"} state={{ from: location }} replace />;
  return userName !== "" ? (
    roles && roles !== "error" && roles?.r.length > 0 ? (
      roles?.r?.find((role) => allowedRoles?.includes(role)) || allowedRoles.length == 0 ? (
        <WithSideBarLayout>
          <DashboardLayout>
            <DashboardNavbar />
            <Outlet />
          </DashboardLayout>
        </WithSideBarLayout>
      ) : (
        <Navigate to={"/error"} state={{ from: location }} replace />
      )
    ) : (
      <Redirect dispatch={dispatch} />
    )
  ) : (
    <Navigate to={"/authentication/sign-in"} state={{ from: location }} replace />
  );
};
RequireAuth.propTypes = {
  allowedRoles: PropTypes.array,
};
export default RequireAuth;
//eslint-disable-next-line
const Redirect = ({ dispatch }) => {
  let isLoading = true;
  useEffect(() => {
    setLogout(dispatch).then(() => isLoading == false);
  }, []);
  useEffect(() => {}, [isLoading]);
  return isLoading && <Navigate to={"/authentication/sign-in"} state={{ from: location }} />;
};
