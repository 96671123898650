import React, { useEffect, useState } from "react";
import TransactionsComponent from "../../../../layoutDependencies/transactionComponent";
import { brandAPI } from "../../../../assets/apiFunctions";

const ManagerDashboard = (props) => {
  const [userTransaction, setUserTransaction] = useState(null);
  useEffect(() => {
    brandAPI.userTransactions().then((res) => setUserTransaction(res.data));
  }, []);
  return (
    <>
      {userTransaction && (
        <TransactionsComponent
          userTransactions={userTransaction}
          showDetails={true}
          title={"weekly transaction"}
        />
      )}
    </>
  );
};

export default ManagerDashboard;
