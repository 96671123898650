import { brandAPI } from "../../../assets/apiFunctions";
import { setLoader, setNotification, useMaterialUIController } from "../../../context";
import messageNotification, {
  ErrorCodes,
  SetNotificationClass,
} from "../../../assets/messageNotification";
import useFormData from "../../../hooks/useFormData";
import { useState } from "react";
import { useBranchesContext } from "../../../context/brancheContext";

const useBranchesHook = () => {
  const [controller, dispatch] = useMaterialUIController();
  const [name, setName] = useState("");
  const { data, setData } = useBranchesContext();

  /**
   * Updates a department with the provided data.
   * @param {Object} updatedValue - The updated department data.
   * @returns {Promise<Object>} - The API response.
   */
  const createOrUpdateData = async (updatedValue) => {
    let not = new SetNotificationClass();
    try {
      setLoader(dispatch, true);
      const res = await brandAPI.createOrUpdate(updatedValue);
      setLoader(dispatch, false);
      setNotification(dispatch, not.SUCCESS_OPERATION());
      return res;
    } catch (error) {
      setLoader(dispatch, false);
      console.error("Error updating department:", error);
      setNotification(dispatch, not.FAILED_OPERATION());
      // return null;
      // throw error; // Rethrow the error to propagate it up the call stack if needed.
    }
  };

  /**
   * Deletes a department with the specified ID.
   * @param {number} id - The ID of the department to delete.
   * @returns {Promise<Object>} - The API response.
   */
  const deleteData = async (id) => {
    let pushNotification = new SetNotificationClass();
    try {
      setLoader(dispatch, true);
      const res = await brandAPI.delete(id);
      setLoader(dispatch, false);
      setNotification(dispatch, pushNotification.SUCCESS_OPERATION());
      return res;
    } catch (error) {
      setLoader(dispatch, false);
      setNotification(dispatch, pushNotification.FAILED_OPERATION());
      console.error("Error deleting department:", error);
      throw error; // Rethrow the error to propagate it up the call stack if needed.
    }
  };
  const submit = (e) => {
    e.preventDefault();
    if (name) {
      setLoader(dispatch, true);
      const formData = useFormData({ name: "data", data: { name: name } });
      createOrUpdateData(formData).then((res) => {
        if (res !== null) {
          setName("");
          setData([...data, res.data.data]);
        }
      });
    }
  };
  const handleChange = (e) => {
    setName(e.target.value);
  };

  return { createOrUpdateData, deleteData, name, submit, handleChange };
};

export default useBranchesHook;
