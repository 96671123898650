import React from "react";
import MDBox from "../../components/MDBox";
import { BranchesContextProvider } from "../../context/brancheContext";
import { Route, Routes } from "react-router-dom";
import _Index from "./_index";
import BrandSetting from "./brandSetting";

//Branches Layout
const Index = (props) => {
  return (
    <MDBox pt={6} pb={3}>
      <BranchesContextProvider>
        <Routes>
          <Route index element={<_Index />} />
          <Route path="settings" element={<BrandSetting />} />
        </Routes>
      </BranchesContextProvider>
    </MDBox>
  );
};

export default Index;
