import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const brandAPI = {
  createOrUpdate: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/create`, data);
  },
  getDataAPI: async () => {
    return await axiosInstance.get(`${prefix.brand}/get`);
  },
  setBrandManager: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/set-brand-manager`, data);
  },
  deleteTransaction: async (id) => {
    return await axiosInstance.post(`${prefix.brand}/delete-transaction/${id}`);
  },
  getBrandWithManager: async () => {
    return await axiosInstance.get(`${prefix.brand}/get-brandWith-manager`);
  },
  changeStatus: async (data, id) => {
    return await axiosInstance.post(`${prefix.brand}/set_brand_status${id}`, data);
  },
  addTransaction: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/set_transaction`, data);
  },
  userTransactions: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/get_user_transaction`, data);
  },
  managerTransactions: async (data) => {
    return await axiosInstance.post(`${prefix.brand}/get_manager_transaction`, data);
  },
  getYesterdayTransaction: async () => {
    return await axiosInstance.get(`${prefix.brand}/get_yesterday_transaction`);
  },
  delete: async (id) => {
    return await axiosInstance.post(`${prefix.brand}/delete/${id}`);
  },
};
