import { brandAPI } from "./prefixApis/brandAPI";

/**
 *
this file contain api function that used in multiple time
 */
export const api_services = {
  getUserTransactions: async () => {
    return await brandAPI.userTransactions();
  },
};
