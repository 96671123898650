import React, { useEffect, useState } from "react";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import { utils } from "../../assets/assetsFunctions";
import { jobsAPI } from "../../assets/prefixApis/jobsAPI";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";

const ViewProfile = () => {
  const [details, setDetails] = useState();
  const d = [
    {
      title: "guest info",
      data: [
        {
          title: "Applicant name",
          value: "Applicant_name",
          nested: "",
        },
        {
          title: "Applicant msg",
          value: "Applicant_msg",
          nested: "",
        },
        {
          title: "Applicant telephone",
          value: "Applicant_telephone",
          nested: "",
        },
        {
          title: "Applicant_years_of_exp",
          value: "Applicant_years_of_exp",
          nested: "",
        },
      ],
    },
  ];
  useEffect(() => {
    const id = utils.getParamsFromUrl("id");
    jobsAPI.getApplication(id).then((res) => setDetails(res.data.data[0]));
  }, []);
  if (!details) return null;
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Reservation Details"} />
        <MDBox
          sx={{ flexDirection: "column", display: "flex", justifyContent: "space-between" }}
          width={"100"}
          py={2}
          pt={3}
          px={3}
        >
          <Grid container width={"100%"} sx={{ justifyContent: "space-between" }} rowGap={2}>
            {d?.map((e, index) => (
              <Grid
                item
                mt={0}
                display={"flex"}
                xs={12}
                md={6}
                gap={1}
                flexDirection={"column"}
                key={index}
              >
                <MDTypography
                  variant="h4"
                  fontWeight="bold"
                  textTransform={"capitalize"}
                  color={"info"}
                  mb={1}
                >
                  {e.title}
                </MDTypography>
                {e?.data?.map((x, index) => (
                  <MDBox
                    display={"flex"}
                    flexDirection={"row"}
                    gap={1}
                    key={index}
                    alignItems={"center"}
                  >
                    <MDTypography variant="h6" fontWeight="bold" textTransform={"capitalize"}>
                      {x.title}:
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="regular">
                      {details && details.hasOwnProperty(x.nested)
                        ? details[x.nested][x.value]
                        : details[x.value]}
                      {x?.prefix}
                    </MDTypography>
                  </MDBox>
                ))}
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
};

export default ViewProfile;
