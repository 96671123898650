import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Add from "./addJob/add";
import { utils } from "../../assets/assetsFunctions";
import { jobsAPI } from "../../assets/prefixApis/jobsAPI";

const Edit = (props) => {
  const [job, setJob] = useState();
  const fetchJob = async () => {
    const id = await utils.getParamsFromUrl("id");
    if (id) {
      try {
        const res = await jobsAPI.getJob(id);
        return res;
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    }
  };

  useEffect(() => {
    fetchJob().then((res) => setJob(res.data.data));
  }, []);
  if (!job) return null;
  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Rooms Add"} />
        <MDBox pt={3}></MDBox>
        <Add dataToUpdate={job} />
      </Card>
      <div>asd</div>
    </MDBox>
  );
};

export default Edit;
