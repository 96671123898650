import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { setLoader, useMaterialUIController } from "../../../context";
import { getGlobalContext } from "../../../context/globalContext";
import { workingHoursAPI } from "../../../assets/prefixApis/workingHoursAPI";
import { brandAPI } from "../../../assets/prefixApis/brandAPI";

const transactionsHooks = () => {
  const today = moment();
  const oneWeekBefore = today.clone().subtract(7, "days");
  const [date, setDate] = React.useState({ startDate: oneWeekBefore, endDate: today });
  const [data, setData] = useState();
  const { users } = getGlobalContext();
  const [selectedUser, setSelectedUser] = useState([]);

  function handleChangeDate(type, e) {
    setDate({ ...date, [type]: e });
  }

  useEffect(() => {
    brandAPI.managerTransactions({ ...date }).then((res) => setData(res.data));
  }, []);
  function submit() {
    // const usersIds = selectedUser.map((e) => e.id);
    brandAPI
      .managerTransactions({
        ...date,
        // user_id: usersIds,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => setData(null));
    // .finally(() => showHideLoader(false));
  }
  return {
    // selectUser,
    submit,
    handleChangeDate,
    date,
    setDate,
    data,
    setData,
    users,
    selectedUser,
    setSelectedUser,
  };
};

export default transactionsHooks;
