import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const View = React.lazy(() => import("./view"));
const Add = React.lazy(() => import("./add"));
const Edit = React.lazy(() => import("./edit"));
const Rates = React.lazy(() => import("./ratesAvailability"));
const Index = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route index element={<View />} />
        <Route path="add" element={<Add />} />
        <Route path="edit" element={<Edit />} />
        <Route path="rates" element={<Rates />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );
};

export default Index;
