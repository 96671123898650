import axiosInstance from "../axiosInstance";
import { prefix } from "./prefixsVariables";

export const jobsAPI = {
  getApplication: async (id = null) => {
    return await axiosInstance.get(`${prefix.jobs}/getApplications/${id ? id : ""}`);
  },
  getResume: async (id) => {
    return await axiosInstance.get(`${prefix.jobs}/getResume/${id}`, {
      responseType: "blob",
    });
  },
  getJob: async (id = null) => {
    return await axiosInstance.get(`${prefix.jobs}/getJob/${id ? id : ""}`);
  },
  setJob: async (data) => {
    return await axiosInstance.post(`${prefix.jobs}/setJob`, data);
  },
  changeStatus: async (data, id) => {
    return await axiosInstance.post(`${prefix.jobs}/change_status/${id}`, data);
  },
  jobDelete: async (id) => {
    return await axiosInstance.post(`${prefix.jobs}/delete/${id}`);
  },
};
