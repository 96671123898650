const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "currency",
};

const patientDetailsFields = [
  {
    ...commonProps,
    name: "day",
    label: "Day",
    value: "day",
    inputType: "date",
    required: true,
  },
  {
    ...commonProps,
    name: "dollar",
    label: "Amount in dollar",
    value: "amount_dollar",
    required: false,
    icon: "$",
  },
  {
    ...commonProps,
    name: "dinar",
    label: "Amount in Dinar",
    value: "dinar",
    required: false,
    icon: "IQD",
  },
];
const formFields = [{ name: "Transaction", fields: patientDetailsFields }];

export default formFields;
