import React, { useEffect, useState } from "react";
import { brandAPI } from "../../../assets/apiFunctions";
import { utils } from "../../../assets/assetsFunctions";
import { SetNotificationClass } from "../../../assets/messageNotification";
import useFormData from "../../../hooks/useFormData";
import { pushMessages } from "../../../hooks/pushMessages";
import moment from "moment";

const _UsePaymentsHooks = (props) => {
  const date = moment();

  const { showMessage, showHideLoader } = pushMessages();
  const [brands, setBrands] = useState();
  const [data, setData] = useState({ day: date });
  function handleChange(e) {
    if (e !== null) {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  }
  async function submit(e) {
    e.preventDefault();
    let allowSend = false;
    Object.entries(data).map(([key, value]) => {
      if (key !== "day") {
        if (value) {
          allowSend = true;
        }
      }
    });
    const setAlert = new SetNotificationClass();
    if (allowSend) {
      showHideLoader(true);
      let updatedData = { ...data };
      //this when user is not an admin
      if (!data.hasOwnProperty("fk_brand_id")) {
        updatedData.fk_brand_id = brands.fk_brand_id;
      }
      const formData = await useFormData({
        name: "data",
        data: updatedData,
      });
      brandAPI
        .addTransaction(formData)
        .then((res) => showMessage(setAlert.SUCCESS_OPERATION()))
        .finally(() => showHideLoader(false));
    } else {
      setAlert.message = "One or more fields must be filled out.";
      showMessage(setAlert.FAILED_OPERATION());
    }
  }
  function getUserBrand() {
    brandAPI.getDataAPI().then((res) => setBrands(utils.returnObjectFromArray(res.data.data)));
  }
  useEffect(() => {
    getUserBrand();
  }, []);
  return { brands, data, submit, handleChange };
};

export default _UsePaymentsHooks;
