import React, { useState } from "react";
import MDSnackbar from "../MDSnackbar";
import PropTypes from "prop-types";
import MDAlert from "../MDAlert";
import MDTypography from "../MDTypography";
import Box from "@mui/material/Box";
const alertContent = (name, text) => (
  <MDTypography variant="h6" color="white" fontWeight={"medium"}>
    {text}
  </MDTypography>
);
const Notification = ({ text, type }) => {
  const [successSB, setSuccessSB] = useState(true);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  return (
    <Box zIndex={1500} position={"fixed"} bottom={0} alignItems={"center"} width={"100%"}>
      <MDAlert
        color={type}
        dismissible
        maxWidth={{ sx: "100%", md: "80%" }}
        style={{ display: "flex", margin: "0 auto" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {alertContent("light", text)}
      </MDAlert>
    </Box>
  );
};

export default Notification;
Notification.propTypes = {
  openSuccessSB: PropTypes.any,
  text: PropTypes.string,
  type: PropTypes.string,
};
Notification.defaultProps = {
  openSuccessSB: false, // Default value for openSuccessSB
  text: "dsadd", // Default value for text
  type: "info", // Default value for type
};
