import React, { useEffect } from "react";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import LayoutHeader from "../../../components/layoutHeader/layoutHeader";
import SubmitForm from "../../../components/forms/submitForm";
import inputs from "./JobInputs";
import { Grid } from "@mui/material";
import MdSelect from "../../../components/MDRadio/MDRadio";
import addJobHooks from "./addJobHooks";
import { DatePicker } from "../../../components/datePicker";
import PropTypes from "prop-types";

const Add = ({ dataToUpdate }) => {
  const { handleChange, submit, job } = addJobHooks(dataToUpdate);

  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Add Patient"} />
        <MDBox pt={3}></MDBox>
        <SubmitForm user={job} submit={submit} handleChange={handleChange} inputs={inputs} />
      </Card>
    </MDBox>
  );
};

export default Add;
Add.propTypes = {
  dataToUpdate: PropTypes.object,
};
