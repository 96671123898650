import React from "react";
import AddBrandPayment from "../../payments/components/addBrandPayment";
import BrandPaymentContextProvider from "../../../context/brandPaymentContext";

const Index = (props) => {
  return (
    <BrandPaymentContextProvider>
      <AddBrandPayment isAdmin={true} />
    </BrandPaymentContextProvider>
  );
};
export default Index;
