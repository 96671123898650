import React from "react";
import { Navigate, Route, Routes, matchPath, useHref } from "react-router-dom";
import Career from "./addJob";
import View from "./view";
import ViewProfile from "./viewProfile";
import Edit from "./edit";

const Index = () => {
  return (
    <>
      <Routes>
        <Route index element={<View />} />
        <Route path="add" element={<Career />} />
        <Route path="edit" element={<Edit />} />
        <Route path="applicable-profile" element={<ViewProfile />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </>
  );
};

export default Index;
