import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import View from "./view";
import Add from "./add";
import Edit from "./edit";

const Index = () => {
  return (
    <Routes>
      <Route index element={<View />} />
      <Route path="add" element={<Add />} />
      <Route path="edit" element={<Edit />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default Index;
