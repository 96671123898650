/* eslint-disable react/prop-types */
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import React from "react";
import MDButton from "../MDButton";
import CircularProgress from "@mui/material/CircularProgress";

const TBNameAndEmail = ({ name = "", email = "", ...rest }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1} {...rest}>
    {/*<MDAvatar src={team2} name={name} size="sm" />*/}
    <MDBox lineHeight={1}>
      <MDTypography
        display="block"
        variant="button"
        fontWeight="medium"
        textTransform={"capitalize"}
      >
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);
const TBText = ({ text = null }) => (
  <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
    {text}
  </MDTypography>
);
const TBDelete = ({ id = "" }) => (
  <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
    delete
  </MDTypography>
);
const TBDate = ({ employed = "" }) => (
  <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
    {employed || "01-01-2022"}
  </MDTypography>
);
const TBPhone = ({ phone = "" }) => (
  <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
    {phone}
  </MDTypography>
);
const TBHyperLink = ({ id = "", text = "", callback = null }) => (
  <MDTypography
    sx={{ textDecoration: "underline", cursor: "pointer" }}
    component="p"
    variant="caption"
    color="info"
    fontWeight="medium"
    onClick={() => callback && callback()}
  >
    {text}
  </MDTypography>
);
const TBStatus = ({ defaultStatus, loadingButton, callback = null }) => (
  <MDBox ml={-1}>
    <MDButton
      color={defaultStatus === 0 || defaultStatus === false ? "success" : "light"}
      size={"small"}
      onClick={() => callback && callback()}
      disabled={loadingButton}
    >
      {loadingButton ? (
        <CircularProgress color={"secondary"} size={"20px"} />
      ) : defaultStatus === 0 || defaultStatus === false ? (
        "Enabled"
      ) : (
        "Disabled"
      )}
    </MDButton>
  </MDBox>
);

export { TBNameAndEmail, TBText, TBDate, TBPhone, TBHyperLink, TBStatus, TBDelete };
