import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import UsersContextProvider from "../../context/usersContext";
import AddUser from "./addUser";

import Edit from "./edit";

const Index = () => {
  // const CheckRendering = () => {
  //   const hasUsers = users && users.length > 0;
  //   return !localLoader ? (
  //     hasUsers ? (
  //       <UsersTable onUserChange={onUserChange} users={users} navigate={navigate} />
  //     ) : (
  //       <div>No data</div>
  //     )
  //   ) : (
  //     contentLoader()
  //   );
  // };
  return (
    <UsersContextProvider>
      <Routes>
        <Route index element={<AddUser />} />
        <Route path="edit" element={<Edit />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </UsersContextProvider>
  );
};

export default Index;
