import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { usersAPI } from "../assets/apiFunctions";
import { useNavigate } from "react-router-dom";

export const UsersContext = React.createContext(null);

const UsersContextProvider = (props) => {
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();

  function updateUsers(data) {
    setUsers([data, ...users]);
  }
  useEffect(() => {
    usersAPI
      .getUsers()
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error); // Log the error
        return null; // Return a value or handle the error here
      });
  }, []);
  return (
    <UsersContext.Provider value={{ users, setUsers, updateUsers, navigate }}>
      {props.children}
    </UsersContext.Provider>
  );
};

UsersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default UsersContextProvider;

export const useUserContext = () => {
  return useContext(UsersContext);
};
