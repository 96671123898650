import React, { useEffect, useState } from "react";
import MDInput from "../../../components/MDInput";
import PropTypes from "prop-types";
import MDButton from "../../../components/MDButton";
import { Icon } from "@mui/material";
import { departmentAPI } from "../../../assets/apiFunctions";
import useBranchesHook from "../hooks/useBranchesHook";
import { useDepartmentContext } from "../../../context/departmentContext";
import { useBranchesContext } from "../../../context/brancheContext";
import useFormData from "../../../hooks/useFormData";

const DataToUpdate = ({ value, changeInputState }) => {
  const [updatedValue, setValue] = useState(value);
  const { deleteData, createOrUpdateData } = useBranchesHook();
  const { data, setData } = useBranchesContext();
  const handleResponse = (res, status) => {
    let updatedData = null;
    if (res == null) return;
    if (status.type === "update") {
      updatedData = data.map((data) => (data.id === res.data?.data?.id ? res.data.data : data));
    }
    if (status.type === "delete") {
      updatedData = data.filter((data) => data.id !== res.data?.data?.id);
    }
    setData(updatedData);
    changeInputState(false);
  };
  const submit = (type) => {
    switch (type) {
      case "update":
        // updates only when the 'name' is actually modified, optimizing efficiency.
        if (updatedValue.name !== value.name) {
          const formData = useFormData({ name: "data", data: updatedValue });
          createOrUpdateData(formData).then((res) => handleResponse(res, { type: "update" }));
        }
        break;
      case "delete":
        deleteData(updatedValue.id).then((res) => handleResponse(res, { type: "delete" }));
        break;
    }
    changeInputState();
  };
  useEffect(() => {
    const i = document.getElementById(value.id);
    if (i) {
      i.focus();
    }
  }, []);
  return (
    <>
      <MDInput
        value={updatedValue?.name}
        id={value.id}
        onChange={(e) => setValue({ ...updatedValue, name: e.target.value })}
      />
      <MDButton variant={"text"} size={"medium"} onClick={() => submit("update")}>
        <Icon color={"success"}>check</Icon>
      </MDButton>
      <MDButton variant={"text"} size={"medium"} onClick={() => submit("delete")}>
        <Icon color={"error"}>delete</Icon>
      </MDButton>
    </>
  );
};

export default DataToUpdate;
DataToUpdate.propTypes = {
  value: PropTypes.object.isRequired,
  changeInputState: PropTypes.func.isRequired,
};
