/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import { usePaymentContext } from "../../../../context/brandPaymentContext";
import moment from "moment";
import { isArray } from "chart.js/helpers";
import TransactionsComponent from "../../../../layoutDependencies/transactionComponent";

function Transactions() {
  const { userTransactions } = usePaymentContext();
  return (
    <Card sx={{ height: "100%" }}>
      <TransactionsComponent userTransactions={userTransactions} showDetails={false} />
    </Card>
  );
}

export default Transactions;
