import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "../MDButton";
import MDBox from "../MDBox";
import { useEffect } from "react";
import MDTypography from "../MDTypography";
import Divider from "@mui/material/Divider";

export default function AlertDialog({
  openDialog,
  disagreeText,
  agreeText,
  icon,
  id,
  onAgree,
  triggerText,
  content,
  disable,
  onClose,
  title,
}) {
  const [open, setOpen] = React.useState(openDialog);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);
  console.log("here");
  return (
    <MDBox>
      <MDBox display={"flex"}>
        {icon && (
          <IconButton
            disabled={disable}
            style={{ marginRight: "10px" }}
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event propagation
              handleClickOpen();
            }}
          >
            {icon}
          </IconButton>
        )}
        {/*<IconButton*/}
        {/*  disabled={disable}*/}
        {/*  style={{ marginRight: "10px" }}*/}
        {/*  aria-label="delete"*/}
        {/*  onClick={(e) => {*/}
        {/*    e.stopPropagation(); // Prevent event propagation*/}
        {/*    handleClickOpen();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <DeleteIcon color={disable ? "action" : "error"} />*/}
        {/*</IconButton>*/}
      </MDBox>
      <Dialog
        id={id}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDBox bgcolor={"grey-100"}>
          <DialogTitle>
            <MDTypography variant="h6" fontWeight="medium">
              {title}
            </MDTypography>
          </DialogTitle>
        </MDBox>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose();
            }}
          >
            {disagreeText}
          </Button>
          <MDButton
            onClick={(e) => {
              onAgree(e);
              handleClose();
            }}
            color={"error"}
          >
            {agreeText}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}
AlertDialog.default = {
  openDialog: false,
};
AlertDialog.propTypes = {
  triggerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  disagreeText: PropTypes.string.isRequired,
  agreeText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node,
  disable: PropTypes.bool,
  openDialog: PropTypes.bool,
};
