import React, { useEffect } from "react";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import MasterCard from "../../examples/Cards/MasterCard";
import CurrencyCard from "../../components/Cards/CurrencyCard";
import AddBrandPayment from "./components/addBrandPayment";
import Transactions from "./components/Transactions";
import { usePaymentContext } from "../../context/brandPaymentContext";

const _Index = () => {
  const { brands } = usePaymentContext();
  return (
    <MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={6}>
                <MasterCard
                  number={4562112245947852}
                  holder={brands?.brand_details?.name}
                  expires=""
                />
              </Grid>
              <Grid item xs={6} xl={3}>
                <CurrencyCard
                  icon="attach_money"
                  title="Dollar"
                  description="Currency Available"
                  value=""
                />
              </Grid>
              <Grid item xs={6} xl={3}>
                <CurrencyCard
                  icon="money"
                  title="dinar"
                  description="Currency Available"
                  value=""
                />
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <PaymentMethod />*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
          {/*<Grid item xs={12} lg={4}>*/}
          {/*  <Invoices />*/}
          {/*</Grid>*/}
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <AddBrandPayment />
      </MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Transactions />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};
export default _Index;
