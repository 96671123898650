import { useMaterialUIController, setLoader, setNotification } from "../context";

export const pushMessages = (props) => {
  const [controller, dispatch] = useMaterialUIController();
  function showMessage(message) {
    setNotification(dispatch, message);
  }
  function showHideLoader(b) {
    setLoader(dispatch, b);
  }
  return { showMessage, showHideLoader };
};
