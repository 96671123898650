import React from "react";
import MDBox from "@mui/material/Box";
import Card from "@mui/material/Card";
import LayoutHeader from "../../../components/layoutHeader/layoutHeader";
import JobApplicantsTable from "../job-applicants-table";
import JobsTable from "./jobsTable";
import Add from "./add";

const AddJob = (props) => {
  return (
    <MDBox pt={6} pb={3}>
      <Add />
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Job applicants"} />
        <JobsTable />
      </Card>
    </MDBox>
  );
};
export default AddJob;
