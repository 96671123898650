export const prefix = {
  user: "/api/user",
  workingHours: "/api/workingHours",
  patient: "/api/patient",
  car: "/api/car",
  shift: "/api/shift",
  statistics: "/api/statistics",
  department: "/api/department",
  rooms: "/api/rooms",
  reservations: "/api/reservations",
  emails: "/api/emails",
  jobs: "/api/jobs/dashboard",
  brand: "/api/dashboard/brand",
};
