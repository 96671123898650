const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "input",
};
const roomDesc = [
  {
    ...commonProps,
    name: "long_desc",
    label: "long description",
    value: "long_desc",
    required: false,
    inputType: "date",
    type: "textArea",
  },
  {
    ...commonProps,
    name: "short_desc",
    label: "short description",
    value: "short_desc",
    required: false,
    inputType: "date",
    type: "textArea",
  },
];
const formFields = [{ name: "Room Description", fields: roomDesc }];

export default formFields;
