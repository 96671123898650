import React from "react";
import PropTypes from "prop-types";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

const LayoutHeader = ({ title }) => {
  return (
    <MDBox
      mx={2}
      mt={-3}
      py={3}
      px={2}
      variant="gradient"
      bgcolor="info"
      borderRadius="lg"
      coloredShadow="info"
      // sx={{
      //   position: "relative",
      // }}
    >
      <MDTypography
        variant="contained"
        color="white"
        sx={{
          "&:hover ~.desc": {
            opacity: 1,
          },
          width: "max-content",
        }}
      >
        {title}
      </MDTypography>
      {/*<MDTypography*/}
      {/*  variant="contained"*/}
      {/*  mt={1}*/}
      {/*  sx={{*/}
      {/*    background: "white",*/}
      {/*    width: "max-content",*/}
      {/*    opacity: 0,*/}
      {/*    transition: "all 1s ease",*/}
      {/*    zIndex: 2,*/}
      {/*    "&:before": {*/}
      {/*      content: `""`,*/}
      {/*      position: "absolute",*/}
      {/*      width: "20px",*/}
      {/*      top: "-18%",*/}
      {/*      left: "50%",*/}
      {/*      transform: `rotate(45deg)`,*/}
      {/*      // height: "20px",*/}
      {/*      background: "white",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  className={"desc"}*/}
      {/*  p={1}*/}
      {/*  bgcolor={"white"}*/}
      {/*>*/}
      {/*  {title}*/}
      {/*</MDTypography>*/}
    </MDBox>
  );
};

export default LayoutHeader;
LayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
