// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import CurrencyCard from "components/Cards/CurrencyCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import AddBrandPayment from "./components/addBrandPayment";
import BrandPaymentContextProvider from "../../context/brandPaymentContext";
import _index from "./_index";

function Payment() {
  return (
    <BrandPaymentContextProvider>
      <_index />
    </BrandPaymentContextProvider>
  );
}

export default Payment;
