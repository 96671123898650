import React, { createContext, useContext, useEffect, useState } from "react";

import { usersAPI } from "../assets/prefixApis/usersAPI";

const ProfileContext = createContext(null);
// eslint-disable-next-line
const ProfileContextProvider = ({children}) => {
  const [data, setData] = useState(null);
  function setToState(d) {
    Array.isArray(d) ? setData(d[0]) : setData(d);
  }
  useEffect(() => {
    usersAPI.profile().then((res) => setToState(res.data.data));
  }, []);

  return <ProfileContext.Provider value={{ data }}>{children}</ProfileContext.Provider>;
};
export default ProfileContextProvider;

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
