import React, { useState } from "react";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import { roomsAPI } from "../../assets/apiFunctions";
import { TBStatus } from "../../components/tableComponents/tableComponents";

//eslint-disable-next-line
const Status = ({status, id}) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [defaultStatus, setDefault] = useState(status);

  function handleChangeRoomStatus() {
    setLoadingButton(true);
    roomsAPI
      .changeRoomStatus({ status: defaultStatus }, id)
      .then((res) => {
        let intValue = defaultStatus ? 1 : 0;
        setDefault(!intValue);
        // onUserChange("update", data, id);
      })
      .finally(() => setLoadingButton(null));
  }

  return (
    <TBStatus
      defaultStatus={defaultStatus}
      callback={handleChangeRoomStatus}
      loadingButton={loadingButton}
    />
  );
};

export default Status;
