import React, { useEffect, useState } from "react";
import { brandAPI, workingHoursAPI } from "../../../assets/apiFunctions";
import moment from "moment";
import { pushMessages } from "../../../hooks/pushMessages";

const DeleteUpdateHooks = (props) => {
  const { showHideLoader } = pushMessages();
  const [data, setData] = useState();
  const today = moment();
  const [date, setDate] = React.useState({ startDate: today, endDate: today });
  function handleChangeDate(type, e) {
    setDate({ ...date, [type]: e });
  }
  function submit() {
    // const usersIds = selectedUser.map((e) => e.id);
    showHideLoader(true);
    brandAPI
      .userTransactions({
        ...date,
        // user_id: usersIds,
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((e) => setData(null))
      .finally(() => showHideLoader(false));
  }

  useEffect(() => {
    brandAPI.userTransactions({ ...date }).then((res) => setData(res.data.data));
  }, []);

  return {
    data,
    date,
    handleChangeDate,
    submit,
  };
};

export default DeleteUpdateHooks;
