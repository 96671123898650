// /* eslint-disable */
import React, { useState } from "react";
import SidenavCollapse from "./SidenavCollapse";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import routes from "../../routes";

const SideNavHooks = ({
  miniSidenav,
  transparentSidenav,
  whiteSidenav,
  darkMode,
  sidenavColor,
  roles,
  collapseName,
}) => {
  const value = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, auth, children, collapseName }) => {
      let returnValue;
      if (auth && !roles?.r?.find((role) => auth?.includes(role))) return;
      if (type === "collapse") {
        returnValue = (
          <SidenavCollapse
            childLinks={children}
            key={key}
            icon={icon}
            name={name}
            parentRoute={href}
          />
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={"white"}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return value;
};

export default SideNavHooks;
