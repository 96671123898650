import React from "react";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import Add from "./add";
import RoomsTable from "./roomsTable";

const View = (props) => {
  return (
    <div>
      <MDBox pt={6} pb={3}>
        <Card sx={{ marginTop: 2 }}>
          <LayoutHeader title={"Rooms Add"} />
          <MDBox pt={3}></MDBox>
          <Add />
        </Card>
        <RoomsTable />
      </MDBox>
    </div>
  );
};

export default View;
