import React, { useState } from "react";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import InputComponent from "../../../../../components/inputsComponent/inputsComponent";
import { FormControl, NativeSelect } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import { brandAPI, roomsAPI } from "../../../../../assets/apiFunctions";
import useFormData from "../../../../../hooks/useFormData";
import { TBStatus } from "../../../../../components/tableComponents/tableComponents";
import MDAlert from "../../../../../components/MDAlert";
import AlertDialog from "../../../../../components/dialog/dialog";
import useBranchesHook from "../../../hooks/useBranchesHook";
import { useBranchesContext } from "../../../../../context/brancheContext";

const Actions = ({ users, brandId, onSuccess, isDisabled }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [defaultStatus, setDefault] = useState(isDisabled);
  const [openAlert, setOpenAlert] = useState(false);
  const [alreadyConnect, setAlreadyConnect] = useState(null);

  async function handleResponse(res) {
    if (res.data.code === 207) {
      setOpenAlert(true);
    }
  }
  async function changeBrandManager(event) {
    // if statement is to prevent process when default value is chosen
    if (event.target.value !== 1) {
      const formData = await useFormData({
        name: "data",
        data: { brandId, userId: event.target.value },
      });
      brandAPI.setBrandManager(formData).then((res) =>
        handleResponse(res).then(() =>
          onSuccess({
            name: "manager",
            value: users.filter((e) => e.id === event.target.value)[0].name,
          })
        )
      );
    }
  }

  function handleChangeBrandStatus() {
    setLoadingButton(true);
    brandAPI
      .changeStatus({ status: defaultStatus }, brandId)
      .then((res) => {
        let intValue = defaultStatus ? 1 : 0;
        setDefault(!intValue);
        onSuccess({ name: "isDisabled", value: !intValue });
        // onUserChange("update", data, id);
      })
      .finally(() => setLoadingButton(null));
  }
  return (
    <MDBox display="flex" gap={2} alignItems={"center"}>
      <AlertDialog
        id={brandId}
        openDialog={openAlert}
        title={"Change brand manager"}
        // onAgree={onAgree}
        content={`user`}
        triggerText={"do you want to continue"}
        disagreeText={"cancel"}
        agreeText={"confirm"}
        onClose={() => setOpenAlert(false)}
      />
      <MDBox mr={1} width={100}>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor={brandId}>
            Manager
          </InputLabel>
          <NativeSelect
            onChange={(e) => changeBrandManager(e)}
            defaultValue={1}
            inputProps={{
              name: "age",
              id: { brandId },
            }}
          >
            <option value={1}>Change</option>
            {users &&
              users.map((user, index) => (
                <option key={index} value={user.id}>
                  {user.name}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
      </MDBox>
      <MDBox mr={1}>
        <TBStatus
          defaultStatus={defaultStatus}
          callback={handleChangeBrandStatus}
          loadingButton={loadingButton}
        />
      </MDBox>
      {/*<MDButton variant="text" color={"dark"}>*/}
      {/*  <Icon>edit</Icon>&nbsp;edit*/}
      {/*</MDButton>*/}
    </MDBox>
  );
};

export default Actions;
Actions.propTypes = {
  users: PropTypes.any,
  brandId: PropTypes.string,
  onSuccess: PropTypes.func,
  isDisabled: PropTypes.bool,
};
