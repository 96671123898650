const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "input",
};

const roomDetails = [
  {
    ...commonProps,
    name: "room_name",
    label: "room name",
    value: "room_name",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "qty",
    label: "Qty",
    value: "qty",
    required: true,
  },
  {
    ...commonProps,
    name: "room_type_id",
    label: "room type",
    value: "room_type_id",
    required: true,
    inputType: "select",
    options: "roomTypes",
  },
];

const roomCapacity = [
  {
    ...commonProps,
    name: "king_bed",
    label: "King size bed",
    value: "king_bed",
    required: false,
    type: "number",
  },
  {
    ...commonProps,
    name: "single_bed",
    label: "single bed",
    value: "single_bed",
    required: false,
    type: "number",
  },
  {
    ...commonProps,
    name: "rooms",
    label: "rooms",
    value: "rooms",
    required: false,
    type: "number",
  },
  {
    ...commonProps,
    name: "space",
    label: "space",
    value: "space",
    required: true,
    type: "number",
  },
  {
    ...commonProps,
    name: "bath_room",
    label: "bath room",
    value: "bath_room",
    required: false,
    type: "number",
  },
  {
    ...commonProps,
    name: "capacity",
    label: "capacity",
    value: "capacity",
    required: true,
    type: "number",
  },
];
const roomDesc = [
  {
    ...commonProps,
    name: "long_desc",
    label: "long description",
    value: "long_desc",
    required: false,
    inputType: "textArea",
    type: "textArea",
  },
  {
    ...commonProps,
    name: "short_desc",
    label: "short description",
    value: "short_desc",
    required: false,
    inputType: "textArea",
    type: "textArea",
  },
];
const formFields = [
  { name: "Room Details", fields: roomDetails },
  { name: "Room capacity", fields: roomCapacity },
  { name: "Room Description", fields: roomDesc },
];

export default formFields;
