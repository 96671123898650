import React, { useState } from "react";
import SubmitForm from "../../components/forms/submitForm";
import Grid from "@mui/material/Grid";
import Label from "../../components/label/label";
import MDInput from "../../components/MDInput";
import useBranchesHook from "./hooks/useBranchesHook";

const Add = (props) => {
  // const [controller, dispatch] = useMaterialUIController();
  const { submit, handleChange } = useBranchesHook();

  return (
    <SubmitForm submit={submit}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} sm={"auto"}>
          <Label name={"Brand Name"} />
          <MDInput onChange={handleChange} required={true} />
        </Grid>
      </Grid>
    </SubmitForm>
  );
};

export default Add;
