import React from "react";
import { Route, Routes } from "react-router-dom";
import _Index from "./_index";
import DeleteUpdateTransaction from "./deleteUpdateTransaction";
import AddTransaction from "./addTransaction";

const Index = () => {
  return (
    <>
      <Routes>
        <Route index element={<_Index />} />
        <Route path={"edit"} element={<DeleteUpdateTransaction />} />
        <Route path={"add"} element={<AddTransaction />} />
      </Routes>
    </>
  );
};

export default Index;
