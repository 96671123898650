/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { utils } from "../../assets/assetsFunctions";
import React from "react";
import { workingHoursAPI } from "../../assets/apiFunctions";
import AlertDialog from "../../components/dialog/dialog";
import { Icon } from "@mui/material";
import BrandCard from "../brands/brandSetting/components/BrandCard";
import Actions from "../brands/brandSetting/components/actions";
import { useMaterialUIController } from "../../context";
import moment from "moment/moment";
import CurrencyText from "../../components/MDTypography/currency";
import Box from "@mui/material/Box";

function TransactionsList({ title, data, shadow, dates }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  function f(data) {
    let formattedDateRange = "";
    if (Array.isArray(data)) {
      const startDate = moment(data[0]);
      const endDate = moment(data[1]);
      formattedDateRange = `${startDate.format("DD")} - ${endDate.format("DD MMMM YYYY")}`;
    } else {
      const d = moment(data);
      formattedDateRange = `${d.format("DD MMMM YYYY")}`;
    }

    return formattedDateRange;
  }
  const renderProfiles = data.map(({ transactions, brand_name, manager }) => (
    // style={{ backgroundColor: "rgba(255, 140, 0,0.42)" }}
    <MDBox
      key={brand_name}
      component="li"
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      gap={{ xs: 2, md: 0 }}
      alignItems="center"
      bgcolor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      // mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={"flex-center"}
        flexDirection={"row"}
      >
        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {brand_name}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="caption" color="text">
          Dollar:&nbsp;&nbsp;&nbsp;
          <CurrencyText
            currencySymbol={"usd"}
            value={transactions?.usd}
            variant="caption"
            fontWeight="medium"
            color={"success"}
          />
        </MDTypography>
      </MDBox>{" "}
      <MDBox>
        <MDTypography variant="caption" color="text">
          Dinar:&nbsp;&nbsp;&nbsp;
          <CurrencyText
            currencySymbol={"iqd"}
            value={transactions?.iqd}
            variant="caption"
            fontWeight="medium"
            color={"success"}
          />
        </MDTypography>
      </MDBox>{" "}
      <MDBox display="flex" alignItems="flex-start">
        <MDBox color="text" mr={0.5} lineHeight={0}>
          <Icon color="inherit" fontSize="small">
            date_range
          </Icon>
        </MDBox>
        <MDTypography variant="button" color="text" fontWeight="regular">
          {dates && f(dates)}
        </MDTypography>
      </MDBox>
    </MDBox>
  ));
  function calculateTotalByCurrency(d) {
    const totals = d.reduce(
      (acc, curr) => {
        acc.iqd += curr?.transactions?.iqd;
        acc.usd += curr?.transactions?.usd;
        return acc;
      },
      { iqd: 0, usd: 0 }
    );

    return (
      <MDBox display={"flex"} alignItems={"center"} gap={1}>
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              width: 10, // Diameter of the circle
              height: 10, // Diameter of the circle
              backgroundColor: "blue", // Color of the circle
              borderRadius: "50%", // This makes it a circle
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white", // Text color inside the circle
              fontSize: "16px", // Font size for the text
            }}
          />

          <CurrencyText
            value={totals?.usd}
            currencySymbol={"usd"}
            fontWeight={"bold"}
            variant={"caption"}
          />
        </MDBox>{" "}
        <MDBox display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              width: 10, // Diameter of the circle
              height: 10, // Diameter of the circle
              backgroundColor: "orange", // Color of the circle
              borderRadius: "50%", // This makes it a circle
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white", // Text color inside the circle
              fontSize: "16px", // Font size for the text
            }}
          />
          <CurrencyText
            value={totals?.iqd}
            currencySymbol={"iqd"}
            variant={"caption"}
            fontWeight={"bold"}
          />
        </MDBox>
      </MDBox>
    );
  }
  return (
    <>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </MDBox>{" "}
      <MDBox pt={2} px={2} display={"flex"}>
        <MDTypography variant="caption" color="text">
          Total:&nbsp;&nbsp;&nbsp;
        </MDTypography>
        <MDTypography variant="h6" fontWeight="medium">
          {data && calculateTotalByCurrency(data)}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="div" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
        {/*<MDBox*/}
        {/*  display="flex"*/}
        {/*  flexDirection="row"*/}
        {/*  pt={2}*/}
        {/*  style={{*/}
        {/*    width: "100%",*/}
        {/*    justifyContent: "space-between",*/}
        {/*    display: "flex",*/}
        {/*    flexDirection: "row",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">*/}
        {/*    Total hours*/}
        {/*  </MDTypography>*/}
        {/*  <MDTypography*/}
        {/*    variant="h6"*/}
        {/*    fontWeight="medium"*/}
        {/*    textTransform="capitalize"*/}
        {/*    color={"success"}*/}
        {/*  >*/}
        {/*    /!*{utils.minutes_to_hhmm(data?.total_hours, "string")}*!/*/}
        {/*  </MDTypography>*/}
        {/*</MDBox>*/}
      </MDBox>
    </>
  );
}

// Setting default props for the ProfilesList
TransactionsList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
TransactionsList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
  dates: PropTypes.object,
};

export default TransactionsList;
