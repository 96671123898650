/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { workingHoursAPI } from "../../../assets/apiFunctions";
import RenderTransaction from "./components/RenderTransaction";
import Icon from "@mui/material/Icon";

function List({ title, transactions, shadow, status }) {
  const renderProfiles =
    transactions &&
    transactions
      .sort((s, f) => f.day.localeCompare(s.day))
      .map((e, index) => (
        // style={{ backgroundColor: "rgba(255, 140, 0,0.42)" }}
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={e.day}
          >
            <MDBox
              style={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MDTypography variant="button" fontWeight="medium">
                {e.day}
              </MDTypography>
              <MDBox display={"flex"} gap={1} color={"info"} alignItems={"center"}>
                <MDTypography variant="h6" fontWeight="medium" color={"info"}>
                  {e?.transaction?.length}
                </MDTypography>{" "}
                <MDTypography variant="button" fontWeight="medium" color={"info"}>
                  <Icon>account_balance_icon</Icon>
                </MDTypography>{" "}
              </MDBox>
            </MDBox>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox component="li" display="flex" alignItems="center" mb={1}>
              {/*<MDBox mr={1}>/!*<MDAvatar src={image} alt="something here" shadow="md" />*!/</MDBox>*/}
              <MDBox
                display="flex"
                flexDirection="column"
                // alignItems="flex-start"
                // justifyContent="center"
                width={"100%"}
              >
                <RenderTransaction transactions={e.transaction} isAdmin={true} />
                {/*{e.transaction.map((f, index) => (*/}
                {/*  <MDBox key={index} width={"100%"}>*/}
                {/*    <RenderTransaction hours={f} isAdmin={true} />*/}
                {/*    /!*<MDBox display="flex" flexDirection="row" gap={{ xs: 1, md: 4 }}>*!/*/}
                {/*    /!*  <MDTypography variant="button" fontWeight="medium">*!/*/}
                {/*    /!*    {f.brand_name}*!/*/}
                {/*    /!*  </MDTypography>{" "}*!/*/}
                {/*    /!*  <MDTypography variant="caption" fontWeight="light">*!/*/}
                {/*    /!*    {f.user_name}*!/*/}
                {/*    /!*  </MDTypography>{" "}*!/*/}
                {/*    /!*  <MDTypography variant="caption" fontWeight="light">*!/*/}
                {/*    /!*    {f.name}*!/*/}
                {/*    /!*  </MDTypography>{" "}*!/*/}
                {/*    /!*  <MDTypography variant="caption" fontWeight="light">*!/*/}
                {/*    /!*    {f.amount}*!/*/}
                {/*    /!*  </MDTypography>*!/*/}
                {/*    /!*  <MDBox>*!/*/}

                {/*    /!*  </MDBox>*!/*/}
                {/*    /!*</MDBox>*!/*/}
                {/*    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} color={"#94a3b8"} />*/}
                {/*  </MDBox>*/}
                {/*))}*/}
              </MDBox>
            </MDBox>
          </AccordionDetails>
        </Accordion>
      ));
  return (
    <>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDBox component="div" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default props for the ProfilesList
List.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
List.propTypes = {
  title: PropTypes.string,
  transactions: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
  status: PropTypes.string,
};

export default React.memo(List);
