import React, { useState } from "react";
import { setLoader, setNotification, useMaterialUIController } from "../../../context";
import { utils } from "../../../assets/assetsFunctions";
import { jobsAPI } from "../../../assets/prefixApis/jobsAPI";
import UseFormData from "../../../hooks/useFormData";
import { SetNotificationClass } from "../../../assets/messageNotification";

const AddJobHooks = (dataToUpdate) => {
  const [controller, dispatch] = useMaterialUIController();
  const [job, setJob] = useState(dataToUpdate);

  const submit = async (e) => {
    e.preventDefault();
    setLoader(dispatch, true);
    const formData = await UseFormData({ name: "job", data: job });
    const notification = new SetNotificationClass();
    jobsAPI
      .setJob(formData)
      .then((res) => {
        setJob({});
        setNotification(dispatch, notification.SUCCESS_OPERATION());
      })
      .catch((e) => setNotification(dispatch, notification.FAILED_OPERATION()))
      .finally(() => setLoader(dispatch, false));
  };
  function handleChange(e, isDate = false, name = "") {
    if (isDate) {
      handleTimeInput(name, e);
      return;
    }

    if (e && e.target) {
      setJob({ ...job, [e.target.name]: e.target.value });
    }
  }
  function handleTimeInput(name, value) {
    let date = utils.monthYearFormat(value);
    setJob({ ...job, [name]: date });
  }
  return {
    submit,
    handleChange,
    job,
  };
};

export default AddJobHooks;
