// /**
// =========================================================
// * Material Dashboard 2 React - v2.2.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
//  =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */
//
// // prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
//
// // @mui material components
// import ListItem from "@mui/material/ListItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Icon from "@mui/material/Icon";
//
// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
//
// // Custom styles for the SidenavCollapse
// import {
//   collapseItem,
//   collapseIconBox,
//   collapseIcon,
//   collapseText,
// } from "examples/Sidenav/styles/sidenavCollapse";
//
// // Material Dashboard 2 React context
// import { useMaterialUIController } from "context";
//
// function SidenavCollapse({ icon, name, active, ...rest }) {
//   const [controller] = useMaterialUIController();
//   const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
//
//   return (
//     <MDBox
//       {...rest}
//       sx={(theme) =>
//         collapseItem(theme, {
//           active,
//           transparentSidenav,
//           whiteSidenav,
//           darkMode,
//           sidenavColor,
//         })
//       }
//     >
//       <ListItemIcon
//         sx={(theme) =>
//           collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
//         }
//       >
//         {typeof icon === "string" ? (
//           <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
//         ) : (
//           icon
//         )}
//       </ListItemIcon>
//       <ListItemText
//         primary={name}
//         sx={(theme) =>
//           collapseText(theme, {
//             miniSidenav,
//             transparentSidenav,
//             whiteSidenav,
//             active,
//           })
//         }
//       />
//     </MDBox>
//   );
// }
//
// // Setting default values for the props of SidenavCollapse
// SidenavCollapse.defaultProps = {
//   active: false,
// };
//
// // Typechecking props for the SidenavCollapse
// SidenavCollapse.propTypes = {
//   icon: PropTypes.node.isRequired,
//   name: PropTypes.string.isRequired,
//   active: PropTypes.bool,
// };
//
// export default SidenavCollapse;
import React, { useState } from "react";
import List from "@mui/material/List";
import { Collapse, ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MDTypography from "../../components/MDTypography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { StarBorder } from "@mui/icons-material";
import MDBox from "../../components/MDBox";
import { NavLink } from "react-router-dom";
// import PropTypes from "prop-types";

const SidenavCollapse = ({ childLinks, icon, name, parentRoute }) => {
  const [open, setOpen] = useState(false);
  //check if it has links children
  const isParent = childLinks !== undefined && childLinks.length > 0;
  function handleClick() {
    setOpen(!open);
  }
  if (!isParent)
    return (
      <NavLink to={parentRoute}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            paddingY: 1.5,
            color: "#fff",
            // selected and (selected + hover) states
            "&&:hover": {
              bgcolor: (theme) => theme.palette.info.main,
              "&, & .MuiListItemIcon-root": {
                color: "#fff",
              },
            },
          }}
        >
          <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>

          <ListItemText
            primary={
              <MDTypography
                color={"white"}
                display="block"
                variant="h6"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {name}
              </MDTypography>
            }
          />
        </ListItemButton>
      </NavLink>
    );
  return (
    <MDBox>
      <ListItemButton
        onClick={handleClick}
        sx={{
          paddingY: 1.5,
          color: "#fff",
          // selected and (selected + hover) states
          "&&:hover": {
            bgcolor: (theme) => theme.palette.info.main,
            "&, & .MuiListItemIcon-root": {
              color: "#fff",
            },
          },
        }}
      >
        <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>
        <MDBox display={"flex"} flexDirection={"row"} sx={{ alignItems: "between", width: "100%" }}>
          <NavLink to={parentRoute}>
            <ListItemText
              primary={
                <MDTypography
                  color={"white"}
                  display="block"
                  variant="h6"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {name}
                </MDTypography>
              }
            />
          </NavLink>
        </MDBox>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {isParent &&
            childLinks.map((c, index) => (
              <NavLink to={`${parentRoute}/${c.link}`} key={index}>
                <ListItemButton
                  sx={{
                    pl: 10,
                    paddingY: 1.5,
                    color: "#fff",
                    // selected and (selected + hover) states
                    "&&:hover": {
                      bgcolor: (theme) => theme.palette.info.main,
                      "&, & .MuiListItemIcon-root": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <MDTypography
                    color={"white"}
                    display="block"
                    variant="h6"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {c.name}
                  </MDTypography>
                </ListItemButton>
              </NavLink>
            ))}
        </List>
      </Collapse>
    </MDBox>
  );
};
export default SidenavCollapse;
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  childLinks: PropTypes.array,
  parentRoute: PropTypes.string,
};
