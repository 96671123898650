import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { brandAPI, departmentAPI, otherAPI, roomsAPI, usersAPI } from "../../assets/apiFunctions";
import MDTypography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const MdSelect = ({ label, properties, handleChange, value }) => {
  const [options, setOptions] = React.useState(null);
  const [selectedContractType, setSelectedContractType] = React.useState("");

  //this function to get options from server
  const getOptions = {
    contract: () => otherAPI.contractTypes().then((res) => setOptions(res.data.data)),
    departments: () => departmentAPI.getDepartmentAPI().then((res) => setOptions(res.data.data)),
    roomTypes: () => roomsAPI.getRoomTypes().then((res) => setOptions(res.data.data)),
    brands: () => brandAPI.getDataAPI().then((res) => setOptions(res.data.data)),
  };

  useEffect(() => {
    let d = properties.options;
    if (d && getOptions[d]) {
      getOptions[d](); // Call the function associated with the provided option
    }
  }, []);
  if (!options) return null;
  return (
    <Box Box sx={{ m: 1 }} justifySelf={"self-start"} xs={12} sm={9} lg={4}>
      {/*{label}*/}
      <TextField
        name={properties.name}
        sx={{
          width: "100%",
          "& > div:first-of-type": {
            padding: "0.86rem !important", // Apply padding to the first child
          },
        }}
        onChange={(e) => {
          handleChange(e);
          setSelectedContractType(e.target.value);
        }}
        id={properties.name}
        select
        value={value}
        label={properties.label}
        // defaultValue="1"
        slotProps={{
          select: {
            native: true,
          },
        }}
      >
        {/*<MenuItem value={"1"}>default</MenuItem>*/}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name || option?.type}
          </MenuItem>
        ))}
      </TextField>
      {/*<Select*/}
      {/*  name={properties.name}*/}
      {/*  label={properties.label}*/}
      {/*  value={value}*/}
      {/*  sx={{*/}
      {/*    width: "100%",*/}
      {/*    "& > div:first-of-type": {*/}
      {/*      padding: "0.86rem !important", // Apply padding to the first child*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  onChange={(e) => {*/}
      {/*    handleChange(e);*/}
      {/*    setSelectedContractType(e.target.value);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {options &&*/}
      {/*    options.map((o) => (*/}
      {/*      <MenuItem key={o.id} value={o.id}>*/}
      {/*        <MDTypography variant={"h7"}> {o.name || o?.type}</MDTypography>*/}
      {/*      </MenuItem>*/}
      {/*    ))}*/}
      {/*</Select>*/}
    </Box>
  );
};

export default MdSelect;
MdSelect.propTypes = {
  label: PropTypes.any,
  properties: PropTypes.any,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};
