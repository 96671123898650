import React, { useEffect, useState } from "react";
import { initialUser } from "./usersInputFildes";
import { useUserContext } from "../../context/usersContext";
import { setLoader, setNotification, useMaterialUIController } from "../../context";
import UseFormData from "../../hooks/useFormData";
import { usersAPI } from "../../assets/prefixApis/usersAPI";
import messageNotification, { SetNotificationClass } from "../../assets/messageNotification";

const userHooks = (props) => {
  const [user, setUser] = useState(initialUser);
  const { updateUsers } = useUserContext();
  const [controller, dispatch] = useMaterialUIController();
  const { users, setUsers } = useUserContext();
  const options = ["admin", "observer", "user"];
  function onUserChange(status, data, id) {
    if (!users) return;
    if (status == "delete") {
      // Filter out the users with the specified id
      const updatedUsers = users.filter((user) => user.id !== id);
      // Update the users state with the filtered list
      setUsers(updatedUsers);
    } else if (status == "update") {
      const updatedUsers = users.map((user) => {
        if (user.id === id) {
          // If the user's ID matches the provided ID, update the status property
          const newStatus = data.status === true || data.status === 1 ? 1 : 0;
          return { ...user, disabled: newStatus };
        }
        return user; // Return the unmodified user for all other cases
      });
      setUsers(updatedUsers);
    }
  }
  const onReset = () => {
    setUser(initialUser);
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeDate = (e) => {
    setUser({ ...user, startDate: e });
  };

  const checkError = () => {
    setUser({ ...user, error: {} });
    const setUserError = (key, message) => {
      setUser({ ...user, error: { ...user.error, [key]: true, [key + "message"]: message } });
    };

    Object.keys(user).forEach((key) => {
      const value = user[key];
      switch (key) {
        case "firstName":
          if (value.length < 3) {
            setUserError(key, "message");
          }
          break;
        case "password":
          if (user.password < 8) {
            setUserError(key, "Password must be at least 8 characters.");
          }
          if (user.password !== user.confirmPassword) {
            setUserError(key, "Passwords don't match.");
          }
          break;
        default:
          break;
      }
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    checkError();
    if (Object.keys(user.error).length === 0) {
      let pushNotification = new SetNotificationClass();
      const formData = await UseFormData({ name: "user", data: user });
      setLoader(dispatch, true);
      usersAPI
        .createUser(formData)
        .then((res) => {
          if (res.data.status === true) {
            setNotification(dispatch, pushNotification.SUCCESS_OPERATION());
            updateUsers(res?.data?.user_details);
            setUser(initialUser);
          }
        })
        .catch(() => setNotification(dispatch, dispatch, pushNotification.FAILED_OPERATION()))
        .finally(() => setLoader(dispatch, false));
    }
  };
  return { setUser, submit, handleChange, user, onReset, handleChangeDate, options, onUserChange };
};

export default userHooks;
