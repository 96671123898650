const commonProps = {
  onChange: "handleChange",
  required: false,
  type: "number",
  inputType: "input",
};

const patientDetailsFields = [
  {
    ...commonProps,
    name: "job_title",
    label: "job title",
    value: "job_title",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "employment_type",
    label: "employment type",
    value: "employment_type",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "department",
    label: "department",
    value: "department",
    required: true,
    type: "text",
  },
  {
    ...commonProps,
    name: "end_date",
    label: "End date",
    value: "end_date",
    inputType: "date",
  },
];
const jobDes = [
  {
    ...commonProps,
    name: "job_desc",
    label: "job desc",
    value: "job_desc",
    required: true,
    type: "text",
    inputType: "textArea",
  },
];

const formFields = [
  { name: "Job Details", fields: patientDetailsFields },
  { name: "Job Description", fields: jobDes },
];

export default formFields;
