// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import MasterCard from "../../../examples/Cards/MasterCard";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "../../billing/components/PaymentMethod";
import Invoices from "../../billing/components/Invoices";
import BrandUserInformation from "./components/BrandUserInformation";
import Transactions from "../../billing/components/Transactions";

function BrandSettings() {
  return (
    <MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <BrandUserInformation />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default BrandSettings;
