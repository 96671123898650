import React, { useEffect, useState } from "react";
import { usersAPI } from "../../../../../assets/apiFunctions";
import PropTypes from "prop-types";
import BrandCard from "./index";

const CardsContainer = ({ brands }) => {
  const [users, setUsers] = useState(null);
  useEffect(() => {
    usersAPI.getUsers().then((res) => setUsers(res.data.data));
  }, []);
  return (
    <>
      {brands &&
        brands.map((brand, index) => {
          let manager = brand?.with_manager[0]?.with_user?.name || "not set";
          return (
            <BrandCard
              brandId={brand.id}
              key={index}
              name={brand.name}
              manager={manager}
              email="oliver@burrito.com"
              vat="FRB1235476"
              users={users}
              isDisabled={brand.disabled}
            />
          );
        })}
    </>
  );
};
export default CardsContainer;
CardsContainer.propTypes = {
  brands: PropTypes.array,
};
