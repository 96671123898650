/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import moment from "moment";

function PrintableData({ rows, columns }) {
  // Render a cell based on the row and column data
  // const renderCell = () =>
  //   columns.map((c, index) => {
  //     return {
  //       [c.accessor]: (row) => {
  //         if (React.isValidElement(row.user)) {
  //           return row.user; // Return React component if it's a valid element
  //         } else {
  //           return <div>{row.user}</div>; // Render text if it's a plain object or string
  //         }
  //       },
  //     };
  //   });
  const renderCell = (row, column) => {
    if (React.isValidElement(row[column])) {
      return row[column]; // Return React component if it's a valid element
    } else {
      return <div>{row[column]}</div>; // Render text if it's a plain object or string
    }
  };

  const newT = columns.reduce((obj, column) => {
    obj[column.accessor] = renderCell;
    return obj;
  }, {});
  return (
    <div style={{ width: "100%" }} className={"shiftsDataTable"}>
      <MDBox>
        <table style={{ minWidth: "500px" }}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  <MDTypography textAlign={"center"}>{column.Header}</MDTypography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <td key={columnIndex}>
                    <MDTypography p={1}>{newT[column.accessor](row, column.accessor)}</MDTypography>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </MDBox>
    </div>
  );

  // function renderCell(row, column) {
  //   if (column.accessor === "user") {
  //     if (React.isValidElement(row.user)) {
  //       return row.user; // Return React component if it's a valid element
  //     } else {
  //       return <div>{row.user}</div>; // Render text if it's a plain object or string
  //     }
  //   } else if (column.accessor === "day") {
  //     return <div>{row.day}</div>;
  //   } else if (column.accessor === "hours") {
  //     return <div>{row.hours}</div>;
  //   } else if (column.accessor === "totalHours") {
  //     return <div>{row.totalHours}</div>;
  //   } else if (column.accessor === "action") {
  //     return <div>{row.action}</div>;
  //   } else {
  //     return "";
  //   }
  // }
}

export default PrintableData;
