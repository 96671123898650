import React, { useEffect, useState } from "react";
import TransactionsList from "./transactionsList";
import Grid from "@mui/material/Grid";
import { DatePicker } from "../../components/datePicker";
import MDButton from "../../components/MDButton";
import transactionsHooks from "./hooks/transactionsHooks";
import MDBox from "../../components/MDBox";
import LayoutHeader from "../../components/layoutHeader/layoutHeader";
import Card from "@mui/material/Card";

const _Index = (props) => {
  const { date, handleChangeDate, data, submit } = transactionsHooks();

  return (
    <MDBox pt={6} pb={3}>
      <Card sx={{ marginTop: 2 }}>
        <LayoutHeader title={"Admin Transactions Page"} />
        <MDBox
          borderRadius="lg"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
          }}
          mt={2}
          p={2}
        >
          <Grid
            container
            gap={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Grid item>
              <DatePicker
                value={date.startDate}
                onChange={(e) =>
                  // setDate(e);
                  handleChangeDate("startDate", e)
                }
              />
            </Grid>
            <Grid item>
              <DatePicker
                value={date.start_date}
                // defaultValue={date.startDate}
                onChange={(e) =>
                  // setDate(e);
                  handleChangeDate("endDate", e)
                }
              />
            </Grid>
            <Grid item bgcolor={"error"}>
              <MDButton variant="gradient" color="info" fullWidth onClick={submit}>
                Search
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        {data && <TransactionsList data={data?.data} title={""} dates={data.dates} />}
      </Card>
    </MDBox>
  );
};

export default _Index;
